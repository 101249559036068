define('ember-data-table/components/number-pagination', ['exports', 'ember', 'ember-data-table/templates/components/number-pagination'], function (exports, _ember, _emberDataTableTemplatesComponentsNumberPagination) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableTemplatesComponentsNumberPagination['default'],
    range: 10,
    min: _ember['default'].computed('links', function () {
      return this.get('links')['first']['number'] || 1;
    }),
    max: _ember['default'].computed('links', function () {
      var max = this.get('links')['last']['number'];
      return max ? max + 1 : max;
    }),
    current: _ember['default'].computed('page', {
      get: function get() {
        return this.get('page') ? parseInt(this.get('page')) + 1 : 1;
      },
      set: function set(key, value) {
        this.set('page', value - 1);
        return value;
      }
    }),
    hasPagination: _ember['default'].computed('max', function () {
      return this.get('max') !== undefined;
    })
  });
});