define('ember-data-table/components/default-data-table-content-body', ['exports', 'ember', 'ember-data-table/templates/components/default-data-table-content-body'], function (exports, _ember, _emberDataTableTemplatesComponentsDefaultDataTableContentBody) {
  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableTemplatesComponentsDefaultDataTableContentBody['default'],
    tagName: '',
    allFields: _ember['default'].computed.oneWay('data-table.parsedFields'),
    firstColumn: _ember['default'].computed('data-table.parsedFields', function () {
      return this.get('data-table.parsedFields')[0];
    }),
    otherColumns: _ember['default'].computed('data-table.parsedFields', function () {
      var fields;

      var _get = this.get('data-table.parsedFields');

      var _get2 = _toArray(_get);

      fields = _get2.slice(1);

      return fields;
    }),
    linkedRoute: _ember['default'].computed.oneWay('data-table.link')
  });
});