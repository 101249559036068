define('ember-data-table/mixins/serializer', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Mixin.create({

    /**
        Parse the links in the JSONAPI response and convert to a meta-object
    */
    normalizeQueryResponse: function normalizeQueryResponse(store, clazz, payload) {
      var result = this._super.apply(this, arguments);
      result.meta = result.meta || {};

      if (payload.links) {
        result.meta.pagination = this.createPageMeta(payload.links);
      }

      return result;
    },

    /**
       Transforms link URLs to objects containing metadata
       E.g.
       {
           previous: '/streets?page[number]=1&page[size]=10&sort=name
           next: '/streets?page[number]=3&page[size]=10&sort=name
       }
        will be converted to
        {
           previous: { number: 1, size: 10 },
           next: { number: 3, size: 10 }
       }
     */
    createPageMeta: function createPageMeta(data) {
      var meta = {};

      Object.keys(data).forEach(function (type) {
        var link = data[type];
        meta[type] = {};

        var a = document.createElement('a');
        a.href = link;
        var query = a.search.slice(1);

        query.split('&').forEach(function (pairs) {
          var _pairs$split = pairs.split('=');

          var _pairs$split2 = _slicedToArray(_pairs$split, 2);

          var param = _pairs$split2[0];
          var value = _pairs$split2[1];

          if (param === 'page[number]') {
            meta[type].number = parseInt(value);
          } else if (param === 'page[size]') {
            meta[type].size = parseInt(value);
          }
        });
        a = null;
      });

      return meta;
    }

  });
});