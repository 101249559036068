define('ember-data-table/mixins/route', ['exports', 'ember', 'jquery'], function (exports, _ember, _jquery) {
  exports['default'] = _ember['default'].Mixin.create({
    queryParams: {
      filter: { refreshModel: true },
      page: { refreshModel: true },
      size: { refreshModel: true },
      sort: { refreshModel: true }
    },
    mergedQueryOptions: {},
    mergeQueryOptions: function mergeQueryOptions(params) {
      return {};
    },
    model: function model(params) {
      var options = {
        sort: params.sort,
        page: {
          number: params.page,
          size: params.size
        }
      };
      // TODO: sending an empty filter param to backend returns []
      if (params.filter) {
        options['filter'] = params.filter;
      }
      _ember['default'].deprecate('mergedQueryOptions is deprecated. Please overwrite the mergeQueryOptions(params) functions of the ember-data-table route mixin to add custom query options', Object.keys(this.get('mergedQueryOptions')).length === 0, { id: 'mixin.route.mergedQueryOptions', until: 'v1.0.0' });
      _jquery['default'].extend(options, this.get('mergedQueryOptions'));
      _jquery['default'].extend(options, this.mergeQueryOptions(params));
      return this.store.query(this.get('modelName'), options);
    }
  });
});
/*jshint unused:false */