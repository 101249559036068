define('ember-data-table/components/data-table-menu-selected', ['exports', 'ember', 'ember-data-table/templates/components/data-table-menu-selected'], function (exports, _ember, _emberDataTableTemplatesComponentsDataTableMenuSelected) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableTemplatesComponentsDataTableMenuSelected['default'],
    init: function init() {
      this._super.apply(this, arguments);
      this.set('data-table.enableSelection', true);
    },
    selectionCount: _ember['default'].computed('data-table.selection.[]', function () {
      return this.get('data-table.selection.length');
    }),
    actions: {
      clearSelection: function clearSelection() {
        this.get('data-table').clearSelection();
      }
    }

  });
});