define('ember-data-table/components/more-menu', ['exports', 'ember', 'ember-data-table/templates/components/more-menu'], function (exports, _ember, _emberDataTableTemplatesComponentsMoreMenu) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableTemplatesComponentsMoreMenu['default'],
    classNames: ['dropdown-button'],

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this._setupDropdown();
    },

    _setupDropdown: function _setupDropdown() {
      // needed until the Materialize.dropdown plugin is replaced
      this.$().attr('data-activates', this.get('_dropdownContentId'));
      this.$().dropdown({
        belowOrigin: false,
        alignment: 'right',
        constrain_width: false
      });
    },

    _dropdownContentId: _ember['default'].computed(function () {
      return this.get('elementId') + '-dropdown-content';
    })
  });
});