define('ember-data-table/components/data-table', ['exports', 'ember', 'ember-data-table/templates/components/data-table'], function (exports, _ember, _emberDataTableTemplatesComponentsDataTable) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableTemplatesComponentsDataTable['default'],
    noDataMessage: 'No data',
    pagination: 'number', // page or number
    isNumberPagination: _ember['default'].computed.equal('pagination', 'number'),
    range: 10,
    lineNumbers: false,
    hasPagination: _ember['default'].computed.bool('content.meta.pagination'),
    enableLineNumbers: _ember['default'].computed.bool('lineNumbers'),
    selection: [],
    hasMenu: false, // set from inner component, migth fail with nested if
    hasSearch: _ember['default'].computed('filter', function () {
      return this.get('filter') || this.get('filter') === '';
    }),
    autoSearch: true,
    filterChanged: _ember['default'].observer('filter', function () {
      this.set('page', 0);
    }),
    enableSelection: _ember['default'].computed.oneWay('hasMenu'),
    parsedFields: _ember['default'].computed('fields', function () {
      var fields = this.get('fields');
      if (_ember['default'].typeOf(fields) === 'string') {
        return fields.split(' ');
      } else {
        return fields || [];
      }
    }),
    addItemToSelection: function addItemToSelection(item) {
      this.get('selection').addObject(item);
    },
    removeItemFromSelection: function removeItemFromSelection(item) {
      this.get('selection').removeObject(item);
    },
    clearSelection: function clearSelection() {
      this.get('selection').clear();
    }
  });
});