define('ember-simple-auth/internal-session', ['exports', 'ember', 'ember-getowner-polyfill'], function (exports, _ember, _emberGetownerPolyfill) {
  var RSVP = _ember['default'].RSVP;
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].ObjectProxy.extend(_ember['default'].Evented, {
    authenticator: null,
    store: null,
    isAuthenticated: false,
    attemptedTransition: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('content', { authenticated: {} });
    },

    authenticate: function authenticate() {
      var _this = this;

      var args = Array.prototype.slice.call(arguments);
      var authenticator = args.shift();
      _ember['default'].assert('Session#authenticate requires the authenticator to be specified, was "' + authenticator + '"!', !_ember['default'].isEmpty(authenticator));
      var theAuthenticator = (0, _emberGetownerPolyfill['default'])(this).lookup(authenticator);
      _ember['default'].assert('No authenticator for factory "' + authenticator + '" could be found!', !_ember['default'].isNone(theAuthenticator));
      return new RSVP.Promise(function (resolve, reject) {
        theAuthenticator.authenticate.apply(theAuthenticator, args).then(function (content) {
          _this._setup(authenticator, content, true).then(resolve, reject);
        }, function (error) {
          _this._clear().then(function () {
            reject(error);
          }, function () {
            reject(error);
          });
        });
      });
    },

    invalidate: function invalidate() {
      var _this2 = this;

      _ember['default'].assert('Session#invalidate requires the session to be authenticated!', this.get('isAuthenticated'));
      return new RSVP.Promise(function (resolve, reject) {
        var authenticator = (0, _emberGetownerPolyfill['default'])(_this2).lookup(_this2.authenticator);
        authenticator.invalidate(_this2.content.authenticated).then(function () {
          authenticator.off('sessionDataUpdated');
          _this2._clear(true).then(resolve, reject);
        }, function (error) {
          _this2.trigger('sessionInvalidationFailed', error);
          reject(error);
        });
      });
    },

    restore: function restore() {
      var _this3 = this;

      return new RSVP.Promise(function (resolve, reject) {
        _this3._callStoreAsync('restore').then(function (restoredContent) {
          var _ref = restoredContent.authenticated || {};

          var authenticator = _ref.authenticator;

          if (!!authenticator) {
            delete restoredContent.authenticated.authenticator;
            (0, _emberGetownerPolyfill['default'])(_this3).lookup(authenticator).restore(restoredContent.authenticated).then(function (content) {
              _this3.set('content', restoredContent);
              _this3._setup(authenticator, content).then(resolve, reject);
            }, function (err) {
              _ember['default'].Logger.debug('The authenticator "' + authenticator + '" rejected to restore the session - invalidating…');
              if (err) {
                _ember['default'].Logger.debug(err);
              }
              _this3.set('content', restoredContent);
              _this3._clear().then(reject, reject);
            });
          } else {
            delete (restoredContent || {}).authenticated;
            _this3.set('content', restoredContent);
            _this3._clear().then(reject, reject);
          }
        }, function () {
          _this3._clear().then(reject, reject);
        });
      });
    },

    _callStoreAsync: function _callStoreAsync(method) {
      var _store;

      for (var _len = arguments.length, params = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        params[_key - 1] = arguments[_key];
      }

      var result = (_store = this.store)[method].apply(_store, params);
      if (typeof result === 'undefined' || typeof result.then === 'undefined') {
        _ember['default'].deprecate('Ember Simple Auth: Synchronous stores have been deprecated. Make sure your custom store\'s ' + method + ' method returns a promise.', false, {
          id: 'ember-simple-auth.session-store.synchronous-' + method,
          until: '2.0.0'
        });
        return RSVP.Promise.resolve(result);
      } else {
        return result;
      }
    },

    _setup: function _setup(authenticator, authenticatedContent, trigger) {
      var _this4 = this;

      return new RSVP.Promise(function (resolve, reject) {
        trigger = !!trigger && !_this4.get('isAuthenticated');
        _this4.beginPropertyChanges();
        _this4.setProperties({
          isAuthenticated: true,
          authenticator: authenticator
        });
        _ember['default'].set(_this4.content, 'authenticated', authenticatedContent);
        _this4._bindToAuthenticatorEvents();
        _this4._updateStore().then(function () {
          _this4.endPropertyChanges();
          if (trigger) {
            _this4.trigger('authenticationSucceeded');
          }
          resolve();
        }, function () {
          _this4.setProperties({
            isAuthenticated: false,
            authenticator: null
          });
          _ember['default'].set(_this4.content, 'authenticated', {});
          _this4.endPropertyChanges();
          reject();
        });
      });
    },

    _clear: function _clear(trigger) {
      var _this5 = this;

      return new RSVP.Promise(function (resolve, reject) {
        trigger = !!trigger && _this5.get('isAuthenticated');
        _this5.beginPropertyChanges();
        _this5.setProperties({
          isAuthenticated: false,
          authenticator: null
        });
        _ember['default'].set(_this5.content, 'authenticated', {});
        _this5._updateStore().then(function () {
          _this5.endPropertyChanges();
          if (trigger) {
            _this5.trigger('invalidationSucceeded');
          }
          resolve();
        }, function () {
          _this5.endPropertyChanges();
          reject();
        });
      });
    },

    setUnknownProperty: function setUnknownProperty(key, value) {
      _ember['default'].assert('"authenticated" is a reserved key used by Ember Simple Auth!', key !== 'authenticated');
      var result = this._super(key, value);
      this._updateStore();
      return result;
    },

    _updateStore: function _updateStore() {
      var data = this.content;
      if (!_ember['default'].isEmpty(this.authenticator)) {
        _ember['default'].set(data, 'authenticated', _ember['default'].merge({ authenticator: this.authenticator }, data.authenticated || {}));
      }
      return this._callStoreAsync('persist', data);
    },

    _bindToAuthenticatorEvents: function _bindToAuthenticatorEvents() {
      var _this6 = this;

      var authenticator = (0, _emberGetownerPolyfill['default'])(this).lookup(this.authenticator);
      authenticator.off('sessionDataUpdated');
      authenticator.off('sessionDataInvalidated');
      authenticator.on('sessionDataUpdated', function (content) {
        _this6._setup(_this6.authenticator, content);
      });
      authenticator.on('sessionDataInvalidated', function () {
        _this6._clear(true);
      });
    },

    _bindToStoreEvents: on('init', function () {
      var _this7 = this;

      this.store.on('sessionDataUpdated', function (content) {
        var _ref2 = content.authenticated || {};

        var authenticator = _ref2.authenticator;

        if (!!authenticator) {
          delete content.authenticated.authenticator;
          (0, _emberGetownerPolyfill['default'])(_this7).lookup(authenticator).restore(content.authenticated).then(function (authenticatedContent) {
            _this7.set('content', content);
            _this7._setup(authenticator, authenticatedContent, true);
          }, function (err) {
            _ember['default'].Logger.debug('The authenticator "' + authenticator + '" rejected to restore the session - invalidating…');
            if (err) {
              _ember['default'].Logger.debug(err);
            }
            _this7.set('content', content);
            _this7._clear(true);
          });
        } else {
          _this7.set('content', content);
          _this7._clear(true);
        }
      });
    })
  });
});