define('ember-data-table/helpers/sort-icon', ['exports', 'ember'], function (exports, _ember) {
  exports.sortIcon = sortIcon;

  function sortIcon(params /*, hash*/) {
    var order = params[0];
    if (order === 'asc') {
      return "arrow_downward";
    } else if (order === 'desc') {
      return "arrow_upward";
    } else {
      return "";
    }
  }

  exports['default'] = _ember['default'].Helper.helper(sortIcon);
});