define('ember-data-table/components/text-search', ['exports', 'ember', 'ember-data-table/templates/components/text-search'], function (exports, _ember, _emberDataTableTemplatesComponentsTextSearch) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableTemplatesComponentsTextSearch['default'],
    classNames: ['data-table-text-search', 'input-field'],
    filter: '',
    value: _ember['default'].computed.oneWay('filter'),
    wait: 2000,
    auto: true,
    placeholder: 'Search',
    isExpanded: _ember['default'].computed.oneWay('filter', function () {
      return this.get('filter').length > 0;
    }),
    _openSearch: function _openSearch() {
      this.set('isExpanded', true);
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        _ember['default'].$('.data-table-text-search').focus();
      });
    },
    _closeSearch: function _closeSearch() {
      this.set('isExpanded', false);
      this.set('filter', '');
      this.set('value', '');
    },
    keyUp: function keyUp(e) {
      if (this.get('isExpanded') && e.keyCode === 27) {
        // escape
        this._closeSearch();
      }
    },
    actions: {
      toggleExpansion: function toggleExpansion() {
        if (this.get('isExpanded')) {
          this._closeSearch();
        } else {
          this._openSearch();
        }
      },
      search: function search() {
        this.set('filter', this.get('value'));
      }
    }
  });
});