define('ember-data-table/components/page-pagination', ['exports', 'ember', 'ember-data-table/templates/components/page-pagination'], function (exports, _ember, _emberDataTableTemplatesComponentsPagePagination) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableTemplatesComponentsPagePagination['default'],
    classNames: ['page-pagination'],
    keys: ['first', 'prev', 'next', 'last'],
    sortedLinks: _ember['default'].computed('keys', 'links', function () {
      var _this = this;

      var result = {};
      this.get('keys').map(function (key) {
        result[key] = _this.get('links')[key];
      });
      if (!this.get('links.prev')) {
        result['first'] = undefined;
      }
      if (!this.get('links.next')) {
        result['last'] = undefined;
      }
      return result;
    }),
    actions: {
      changePage: function changePage(link) {
        this.set('page', link['number'] || 0);
        if (link['size']) {
          this.set('size', link['size']);
        }
      }
    }
  });
});