define('ember-data-table/components/data-table-content-body', ['exports', 'ember', 'ember-data-table/templates/components/data-table-content-body'], function (exports, _ember, _emberDataTableTemplatesComponentsDataTableContentBody) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberDataTableTemplatesComponentsDataTableContentBody['default'],
    tagName: 'tbody',
    offset: _ember['default'].computed(function () {
      var offset = 1; //to avoid having 0. row
      var page = this.get('data-table.page');
      var size = this.get('content.meta.pagination.first.size');
      if (this.get('data-table.hasPagination') && page && size) {
        //calculate the offset if we have pagination
        offset += page * size;
      }
      return offset;
    }),
    wrappedItems: _ember['default'].computed('content', 'data-table.selection.[]', function () {
      var selection = this.get('data-table.selection');
      return this.get('content').map(function (item) {
        return { item: item, isSelected: selection.includes(item) };
      });
    }),
    actions: {
      updateSelection: function updateSelection() {
        var _this = this;

        this.get('wrappedItems').forEach(function (wrapper) {
          if (wrapper.isSelected) {
            _this.get('data-table').addItemToSelection(wrapper.item);
          } else {
            _this.get('data-table').removeItemFromSelection(wrapper.item);
          }
        });
      }
    }
  });
});